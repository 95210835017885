import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { USER } from "../../config"
import { getAmount } from "../../helpers/price"
import { useEditTipMutation, useGetTipsMutation } from "../../services/cart"
import { setCurrentCart, setTips } from "../../slices/cart"
import { Loader } from "../../components";

const Component = ({ className,cart_id }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation()
    const { currentCart } = useSelector(state => state.cart)
    const { tips } = useSelector(state => state.cart)
    const [selected, setselected] = useState("")
    const [getTips] = useGetTipsMutation();
    const [editTip, { isLoading }] = useEditTipMutation();

    const fetchData = useCallback(async () => {
        const res = await getTips().unwrap();
        if (res.tips) {
            dispatch(setTips(res.tips));
        }
    }, [])

    const handleChange = async () => {
        const res = await editTip({
            id: currentCart.id,
            params: {
                tip_id: selected,
                refresh_payments:currentCart?.email?true:false
            }
        }).unwrap();
        if (res.cart) {
            dispatch(setCurrentCart(res.cart));
        }
    }

    useEffect(() => {
        if(cart_id !== currentCart.id || tips.length === 0){
            fetchData();
        }
    }, [])

    useEffect(() => {
        if (tips.length >0 && currentCart.tip_id) {
            setselected(currentCart.tip_id);
        }else if(tips.length >0){
            setselected(tips[tips.length - 1].id)
        }
    }, [tips])

    useEffect(() => {
        if (selected) {
            handleChange();
        }
    }, [selected])

    return (
        <div className={`tip ${className}`}>
            <input name="tip" value={selected} hidden onChange={console.log} />
            {currentCart.items[0]?.variant?.product?.creator_description ? <p className="mt-4 ms-3 mb-3 mr-3" >{currentCart.items[0]?.variant?.product?.creator_description}</p> : <p className="mt-4 ms-3 mb-3 mr-3" >{t("checkout.Tip Text")}</p>}
            {currentCart.items[0]?.variant?.product?.creator_name && 
            <div className="tip-user m-4">
                <div style={{ backgroundImage: `url(${currentCart.items[0]?.variant?.product?.creator_avatar || USER.image})` }} className="img" />
                <div className="tip-content" >
                    <p className="title" ><b>{currentCart.items[0]?.variant?.product?.creator_name || USER.title}</b></p>
                    <span className="description" >{currentCart.items[0]?.variant?.product?.creator_title || USER.description}</span>
                </div>
            </div>}
            <div className="tips-con" >
                <div className="tips" >
                    {tips.map((el, i) => (
                        <div key={i?.toString()} className={`tip-item ${selected === el.id ? 'active' : ''}`} onClick={() => setselected(el.id)} >
                            <span><b>{el.percent > 0 ? el.name : t("checkout.None")}</b></span>
                            <span>{`${el.percent > 0 ? getAmount(parseInt((currentCart.subtotal * (el.percent / 100)).toFixed(0)), currentCart.region) : getAmount("0", currentCart.region)}`}</span>
                        </div>
                    ))}
                </div>
                {currentCart.tip_total > 0 && <p className="ml-3 mt-3 mb-0">{t("checkout.thank_you_tip")}</p>}
            </div>
        </div>
    )
}

export default Component
