
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ProductItem, Loader, SvgCheckMark } from "../../components";
import { useEffect } from "react";
import { useGetCartMutation } from "../../services/cart";
import { setCurrentCart } from "../../slices/cart";
import { useDispatch, useSelector } from "react-redux";
import { BsEmojiNeutral, BsInfoCircle, BsArrowRight } from "react-icons/bs";
import { getAmount, getRawAmount } from "../../helpers/price";
import { useHistory, useParams } from "react-router-dom";
import { useCompleteOrderMutation, useGetOrderByIdMutation } from "../../services/order";
import { setCreatedOrder } from "../../slices/order";
import ReactGA from "react-ga4";
import TiktokPixel from 'tiktok-pixel';
import { setHeadInfo } from "../../helpers/meta";
import { getCountryFullName } from "../../helpers/location";

const Page = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams();
    const { t } = useTranslation();
    const { currentCart } = useSelector(state => state.cart)
    const { createdOrder } = useSelector(state => state.order)
    const [getCart, { isLoading }] = useGetCartMutation();
    const [getOrder, { isLoading: isLoading2 }] = useGetOrderByIdMutation();
    const [completeOrder, { isLoading: isLoading3 }] = useCompleteOrderMutation();

    const fetchCart = useCallback(async () => {
        if (id) {
            const res = await getCart(`cart_${id}`).unwrap()
            if (res.cart) {
                if (res.cart.payment) {
                    dispatch(setCurrentCart(res.cart));
                    await completeOrder(res.cart.payment.order_id).unwrap()
                    const res2 = await getOrder(res.cart.payment.order_id).unwrap()
                    if (res2.order) {
                        dispatch(setCreatedOrder(res2.order));
                    }
                } else {
                    history.replace(`/checkout/${id}`)
                }
            }
        }
    }, [id, getCart, history, dispatch, getOrder])

    const pushGA = useCallback(() => {
        if (currentCart.payment && createdOrder.id && currentCart.sales_channel.googleanalytics) {
            ReactGA.initialize(currentCart.sales_channel.googleanalytics);
            ReactGA.send({ hitType: "pageview", title: "Thank you page" })
        }
        if (currentCart.payment && createdOrder.id && currentCart.sales_channel.tiktokpixel) {
            TiktokPixel.init(currentCart.sales_channel.tiktokpixel);
            TiktokPixel.track("PlaceAnOrder", {
                content_type: 'product',
                quantity: currentCart.items[0].quantity,
                content_name: currentCart.items[0].title,
                content_id: currentCart.items[0].variant?.id,
                currency: currentCart.region.currency_code,
                value: getRawAmount(currentCart.items[0].total, currentCart.region),
            });
        }
    }, [currentCart.payment, createdOrder]);

    
    useEffect(() => {
        fetchCart()
    }, [fetchCart]);

    useEffect(() => {
        pushGA();
    }, [pushGA]);

    useEffect(() => {
        if (currentCart.sales_channel?.favicon_url) {
            setHeadInfo(currentCart.sales_channel.favicon_url, currentCart.sales_channel.name);
        }
    }, [currentCart.sales_channel])


    return (
        <div className="success" >
            <section className="section1" >
                {(isLoading || isLoading2 || isLoading3 || !currentCart.id) ?
                    <Loader className={"flex-center"} />
                    :
                    currentCart.id && createdOrder.id ?
                        <div className="container" >
                            <form  >
                                <div className="row" >
                                    <div className="col-lg-7 col-md-6 left" >
                                        {currentCart?.sales_channel?.logo_url &&
                                            <>
                                                <img alt={currentCart?.sales_channel?.name} src={currentCart?.sales_channel?.logo_url} className="logo mt-4" />
                                                <div className="divider mt-3 mb-3" ></div>
                                            </>
                                        }
                                        <SvgCheckMark />
                                        <p className="mt-4 mb-0" >{t('success.Order', { order: createdOrder.display_id })}</p>
                                        <h2 className="mb-3" >{t('success.Thank you', { name: createdOrder.shipping_address?.first_name })}</h2>
                                        <h4>{t('Your order is successfully placed.')}</h4>
                                        <p className="mb-4" >{t('success.We’ve accepted your order and it’s completed. A confirmation email was sent to')} <b>{currentCart.email}</b></p>
                                        <div className="info-message">
                                            <div><BsInfoCircle size={30} color="#788daf" /></div>
                                            <p className="mb-0">If you do not receive a confirmation email within a few minutes, please check your Spam or Updates folder.</p>
                                        </div>
                                        {createdOrder.items[0].variant.is_only_digital && createdOrder.items[0].variant.download_url &&
                                            <div className="order-items mb-4" >
                                                <h4 className="mb-4" >{t('success.Order items')}</h4>
                                                {createdOrder.items.map((prod) => (
                                                    <ProductItem key={prod.id} item={prod} region={currentCart.region} isSuccess />
                                                ))}
                                            </div>
                                        }
                                        <button
                                            onClick={() => {
                                                window.open(`${currentCart.sales_channel?.site_url}/orders-tracking?email=${currentCart.email}`, "_blank");    
                                            }}
                                            className="btn btn-secondary btn-block" >{createdOrder.items[0].variant.is_only_digital ? t('success.View your order & files'):  t('success.View your order')}  <BsArrowRight color="white" size={18} /></button>
                                        
                                    </div>
                                    <div className="col-lg-4 col-md-6 right product-view-con" >
                                        <div className="product-view" >
                                            <h6 className="showcart">{t('Shopping Cart')}</h6>
                                            {createdOrder.items.map((prod) => (
                                                <ProductItem key={prod.id} item={prod} region={createdOrder.region} />
                                            ))}
                                            <div className="divider mb-4" ></div>
                                            {createdOrder.subtotal > 0 &&
                                                <div className="d-flex align-item-start justify-content-between mb-2" >
                                                    <span>{t('checkout.Sub total')}:</span>
                                                    <span>{getAmount(createdOrder.subtotal, createdOrder.region)}</span>
                                                </div>
                                            }
                                            {!createdOrder.items[0].variant.is_only_digital &&
                                                <div className="d-flex align-item-start justify-content-between mb-2" >
                                                    <span>{t('checkout.Shipping Fees')}:</span>
                                                    <span>{createdOrder.shipping_total > 0 ? getAmount(createdOrder.shipping_total, createdOrder.region) : t('checkout.Free')}</span>
                                                </div>
                                            }
                                            {createdOrder.tax_total > 0 &&
                                                <div className="d-flex align-item-start justify-content-between mb-2" >
                                                    <span>{t('checkout.Fees')}:</span>
                                                    <span>{getAmount(createdOrder.tax_total, createdOrder.region)}</span>
                                                </div>
                                            }
                                            {createdOrder.tip_total > 0 &&
                                                <div className="d-flex align-item-start justify-content-between mb-2" >
                                                    <span>{t('checkout.Tip')}:</span>
                                                    <span>{getAmount(createdOrder.tip_total, createdOrder.region)}</span>
                                                </div>
                                            }
                                            {createdOrder.discount_total > 0 &&
                                                <div className="d-flex align-item-start justify-content-between mb-2" >
                                                    <span>{t('checkout.Discount')}:</span>
                                                    <span>{getAmount(createdOrder.discount_total, createdOrder.region)}</span>
                                                </div>
                                            }
                                            {createdOrder.total > 0 &&
                                                <div className="d-flex align-item-start justify-content-between mb-2" >
                                                    <span>{t('checkout.Total')}:</span>
                                                    <span><span className="currency_title">{currentCart.region.currency_code}</span> <b>{getAmount(createdOrder.total, createdOrder.region)}</b></span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-lg-7 col-md-6 left" >
                                        <div className="divider mb-4 mt-3" ></div>
                                            <h4 className="mb-4" >{t('success.Customer information')}</h4>
                                            <div className="mb-3">
                                            <p className="mb-0">{t('success.Email')}: {currentCart.email}</p>
                                            {createdOrder.shipping_address?.first_name && <p className="mb-0">{t('success.Full name')}: {`${createdOrder.shipping_address.first_name} ${createdOrder.shipping_address.last_name}`}</p>}
                                            {createdOrder.shipping_address?.address_1 &&
                                                <div className="mb-3">
                                                <p className="mb-0">{t('success.address')}: {createdOrder.shipping_address.address_1} {createdOrder.shipping_address?.address_2 && <> {createdOrder.shipping_address.address_2} </>}
                                                </p>
                                                <p className="mb-0">{t('success.city')}: {createdOrder.shipping_address.city}</p>
                                                <p className="mb-0">{t('success.zip_code')}: {createdOrder.shipping_address.postal_code}</p>
                                                <p className="mb-0">{t('success.country')}: {getCountryFullName(createdOrder.shipping_address.country_code)}</p>
                                                </div>
                                            }
                                            </div>
                                            <p>{t('success.Payment method')}: <span className="text-capitalize">
                                            {currentCart.payment?.provider_id == "stripe" ? t('Credit card') : currentCart.payment?.provider_id}
                                            </span></p>
                                            <p>{t('success.Payment status')}: <span className="text-capitalize"><b>
                                            {createdOrder.payment_status == "captured" ? t('Completed') : createdOrder.payment_status}
                                            </b></span></p>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                            <p className="text-center text-sm pt-3 pb-3 mb-0">{t('Copyright')} © {new Date().getFullYear()}. {currentCart.sales_channel.name}. {t('All rights reserved')}</p>
                                    </div>
                                </div>
                            </form>
                        </div>
                        :
                        <div className="flex-center" >
                            <BsEmojiNeutral size={100} color="#e5e5e5" />
                            <h5 className="mt-4" >{t('checkout.Empty cart')}</h5>
                            <a href="/" className="bnt btn-primary">{t('checkout.Explore products')}</a>
                        </div>
                }
            </section >
        </div >
    );
}

export default Page;