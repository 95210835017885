import axios from 'axios';

export const getLocation = async () => {
    try {
        const res = await axios.get('https://ip.wetrd.com/locate/');
        if (res.data) {
            return {
                country: res.data.country ? res.data.country.iso_code : null,
                city: res.data.city.names ? res.data.city.names.en : null,
                state: res.data.subdivisions[0].iso_code ? res.data.subdivisions[0].iso_code : null,
                zip: res.data.postal ? res.data.postal.code : null
            }
        }
    } catch (error) {
        return {
            country: null,
            city: null,
            state: null,
            zip: null
        }
    }
}

export const getCountryFullName = (country_code) => {
        let regionNames = new Intl.DisplayNames(['en'], {type: 'region'});
        return regionNames.of(country_code.toUpperCase());
}