export const getDeliveryPayload = (formRef, currentCart,country_code,postal_code,city) => {
    const billing_address = {
        address_1: formRef.current?.address_1?.value,
        city: city,
        country_code: country_code || currentCart.shipping_address?.country_code,
        first_name: formRef.current?.first_name?.value,
        last_name: formRef.current?.last_name?.value,
        phone: formRef.current?.phone?.value,
        postal_code: postal_code,
        province: formRef.current?.province?.value,
        address_2: formRef.current?.address_2?.value,
    }
    return {
        billing_address,
        email: formRef.current?.email?.value,
        shipping_address: billing_address
    }
}