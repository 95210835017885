import { useTranslation } from "react-i18next"
import { FaDropbox, FaGoogleDrive } from "react-icons/fa";
import { FiDownload } from "react-icons/fi";
import { getAmount } from "../../helpers/price"
import CryptoJS from 'crypto-js'

const Component = ({ item, region, isSuccess, isLarge }) => {
    const { t } = useTranslation();
    const imgSuffix = isSuccess ? '?auto=format,webp&w=300&h=300' : '?auto=format,webp&w=120&h=120';
    return (
        <div className={`product-item ${isLarge ? 'large' : ''}`}>
        <div className="product-column">
            <div style={{ backgroundImage: `url(${item.thumbnail}${imgSuffix})` }} className={`img ${isSuccess ? 'large' : ''}`} >
                {!item.variant?.is_only_digital && <span className="badge" >{item.quantity}</span>}
            </div>
        </div>
        <div className="product-column">
            <div className="prod-content" >
                <p className="title" >{item.title}</p>
                {!isSuccess && item.variant?.title != item.title && <span className="description" >{item.variant?.title}</span>}
                {isSuccess && item.variant.download_url &&
                    <a target={"_blank"} href={CryptoJS.AES.decrypt(item.variant.download_url, "-JaNcRfUjXn2r5u8x/A-D(G-KbPeSgVk").toString(CryptoJS.enc.Utf8)} className="btn btn-dark mx-1" >
                        <FiDownload color="#007bff" size={16} />  {t('orders.Download File')}
                    </a>
                }
                {isSuccess && item.variant.drive_url &&
                    <a target={"_blank"} href={CryptoJS.AES.decrypt(item.variant.drive_url, "-JaNcRfUjXn2r5u8x/A-D(G-KbPeSgVk").toString(CryptoJS.enc.Utf8)} className="btn btn-dark mx-1" >
                        <FaGoogleDrive color="#007bff" size={16} />  {t('orders.Download From G drive')}
                    </a>
                }
                {isSuccess && item.variant.dropbox_url &&
                    <a target={"_blank"} href={CryptoJS.AES.decrypt(item.variant.dropbox_url, "-JaNcRfUjXn2r5u8x/A-D(G-KbPeSgVk").toString(CryptoJS.enc.Utf8)} className="btn btn-dark mx-1" >
                        <FaDropbox color="#007bff" size={16} />  {t('orders.Download From Dropbox')}
                    </a>
                }
                {isLarge && <span className="price" >{getAmount(item.total, region)}</span>}
            </div>
        </div>
        <div className="product-column">
            {!isSuccess && !isLarge && <span className="price" >{getAmount((item.unit_price), region)}</span>}
        </div>
        </div>
    )
}

export default Component
