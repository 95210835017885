
import React, { useCallback } from "react";
import { Loader, PaypalForm, ProductItem, Stepper, StripeButton } from "../../components";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useCreateUpsellMutation } from "../../services/cart";
import { setCurrentCart } from "../../slices/cart";
import { useQuery } from "../../hooks/router";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { useHistory } from "react-router-dom";
import { setToast } from "../../slices/global";
import { setHeadInfo } from "../../helpers/meta";
import { BsArrowRight } from "react-icons/bs";
import { getAmount } from "../../helpers/price";
const stripePromise = loadStripe(process.env.REACT_APP_PUB_KEY_STRIPE);

const Page = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const prod_id = useQuery().get('prod_id');
    const cart_id = useQuery().get('cart_id');
    const isAlreadyCreated = useQuery().get('created');
    const { t } = useTranslation();
    const { currentCart } = useSelector(state => state.cart)
    const [createUpsell, { isLoading }] = useCreateUpsellMutation();

    const postUpsell = useCallback(async () => {
        try {
            const res = await createUpsell({
                prod_id,
                cart_id
            }).unwrap();

            if (res.cart) {
                dispatch(setCurrentCart(res.cart))
            }
        } catch (error) {
            dispatch(setToast({
                visible: true,
                type: "danger",
                title: "Error",
                text: error.data?.error
            }))
        }
    }, [prod_id, cart_id])

    const handleSkip = () => {
        const down_sell = currentCart.items[0].variant?.downsell_id;
        console.log("currentCart.items[0]",currentCart.items[0])
        if (down_sell) {
            history.replace(`/upsell?prod_id=${down_sell}&cart_id=${cart_id}`)
        } else {
            history.replace(`/success/${cart_id.split('cart_')[1]}`)
        }
    }

    const handleSubmit = () => {
        history.replace(`/complete?id=${currentCart.id.split('cart_')[1]}&parent_cart_id=${cart_id}&sold_variant=${currentCart.items[0]?.variant_id}`)
    }

    useEffect(() => {
        if (!isAlreadyCreated) {
            postUpsell()
        }
    }, [isAlreadyCreated,postUpsell]);

    useEffect(() => {
        if (currentCart.sales_channel?.favicon_url) {
            setHeadInfo(currentCart.sales_channel.favicon_url, currentCart.sales_channel.name);
        }
    }, [currentCart.sales_channel])

    return (
        <div className="upsell" >
            <section className="section1" >
                {!isLoading && currentCart.items?.length > 0 ?
                    <>
                        <Stepper />
                        <div className="upsell_header">
                            <div className="container" >
                                <div className="row" >
                                    <div className="col-md-10 mx-auto" >
                                        <h3 className="text-center" >{currentCart.shipping_address.first_name}, wait! your order is almost complete...</h3>
                                        <h3 className="text-success">{t('We have a special one time offer just for you.')}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container" >
                            <div className="row" >
                                
                                        
                                        { currentCart.images ? 
                                            currentCart.images.map((item) => (
                                                <div className="col-md-6" ><div className="img_upsell mb-2" ><img src={`${item.url}?auto=format,webp&w=800`} /></div></div>
                                            ))
                                           :
                                           <div className="col-md-8 mx-auto" ><div className="img_upsell" ><img src={`${currentCart.items[0].thumbnail}?auto=format,webp&w=800`} /></div></div>
                                        }
                                    
                                <div className="col-md-8 mx-auto" >
                                    <div className="container_price">
                                        <h2>{currentCart.items[0].title}</h2>
                                        <div className="price-box upsell_price">
                                            <div className="price-info">
                                                <div className="price_cont">
                                                    <p className="price_dashed_title">Total value</p>
                                                    <p className="price_dashed">{getAmount((currentCart.items[0].unit_price * 1.4), currentCart.region)}</p>
                                                </div>
                                                <p className="price_final">
                                                    {getAmount((currentCart.items[0].unit_price), currentCart.region)}
                                                </p>
                                                <p className="discount" >40% OFF - But only TODAY, {new Date().toLocaleString("default", { weekday: "long" })}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card p-3 mt-3 mb-3" >
                                        <div className="mb-4 mt-3" dangerouslySetInnerHTML={{ __html: currentCart.desc}}> 
                                        </div>
                                    </div>
                                    <div className="upsell_buttons">
                                    {currentCart?.payment_session?.provider_id === "stripe" &&
                                            <Elements stripe={stripePromise}>
                                                <StripeButton parent_cart_id={cart_id} />
                                            </Elements>
                                        }
                                        {currentCart?.payment_session?.provider_id === "paypal" &&
                                            <PayPalScriptProvider
                                                options={{
                                                    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
                                                    currency: currentCart?.region.currency_code.toUpperCase(),
                                                    intent: "authorize",
                                                    "disable-funding": "card,credit,paylater",
                                                }}>
                                                <PaypalForm parent_cart_id={cart_id} />
                                            </PayPalScriptProvider>
                                        }
                                        {currentCart?.payment_session?.provider_id === "manual" &&
                                            <button
                                                onClick={handleSubmit}
                                                type="button"
                                                className={`btn btn-primary w-100 ${isLoading ? 'loading' : ''}`} >
                                                {isLoading ?
                                                    <Loader size={20} />
                                                    :
                                                    <>{t('Yes, Add it to my order')} <BsArrowRight color="white" size={18} /></>
                                                }

                                            </button>
                                        }
                                        <button className="btn btn-light btn-block" onClick={handleSkip} >{t('No, Thanks')}</button>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <Loader className={'flex-center'} />
                }
            </section >
        </div >
    );
}

export default Page;