import { configureStore } from '@reduxjs/toolkit'
import api from '../services/apiClient'
import slices from "../slices";
import {persistReducer,persistStore} from 'redux-persist' 
import storage from 'redux-persist/lib/storage' 

const persistConfig = {
    key: 'roott',
    storage:storage,
    whitelist: ["cart"]
  }
  
const persistedReducers = persistReducer(persistConfig, slices)

export const store = configureStore({
    reducer: persistedReducers,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                //ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
                ignoredPaths: ["uploads"]
            },
        }).concat(api.middleware),
})

export const persistor = persistStore(store)
