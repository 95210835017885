
import React, { useState, useEffect, useCallback, useRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { PhoneInput } from "../../components";
import { getDeliveryPayload } from "../../helpers/delivery";
import { validateEmail } from "../../helpers/validation";
import { useGetCountriesMutation, usePostCartMutation } from "../../services/cart";
import { setCountries, setCurrentCart } from "../../slices/cart";
import { setToast } from "../../slices/global";
import { getStatesByCountryCode } from "countries-states-data";
import { BsInfoCircle, BsPlusCircle } from "react-icons/bs";
import { getLocation } from "../../helpers/location";

const Component = ({ formRef, fetchShippings,country,setcountry }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const { currentCart, countries } = useSelector(state => state.cart)
    const [postCart, { isLoading }] = usePostCartMutation();
    const [getCountries, { isLoading: isLoading2 }] = useGetCountriesMutation();
    const [states, setstates] = useState([])
    const [city, setcity] = useState(currentCart.shipping_address?.city || '')
    const [zip, setzip] = useState(currentCart.shipping_address?.postal_code || '')
    const [state, setstate] = useState('')
    const [additionalAddress, setadditionalAddress] = useState(currentCart.shipping_address?.address_2 ? true : false)

   
    useEffect(()=>{
        if(currentCart.region_id){
            handleChange()
        }
    },[currentCart.region_id])

    const fetchCountries = useCallback(async () => {
        const res = await getCountries().unwrap()
        dispatch(setCountries(res.countries));
    }, [getCountries, dispatch])

    useEffect(() => {
        fetchCountries();
    }, [fetchCountries]);


    useEffect(()=>{
        if(currentCart.shipping_address?.country_code){
            setcountry(currentCart.shipping_address?.country_code);
            setstates(getStatesByCountryCode(currentCart.shipping_address?.country_code.toUpperCase()))
        }
    },[currentCart.shipping_address?.country_code])
/*
    useEffect(()=>{
        setCountryFirstTime()
    },[])

    const setCountryFirstTime = async () => {
        const res = await getLocation();

        if (res.state) {
            setstate(res.state)
            
        }

        if (res.city) {
            setcity(res.city)
        }

        if (res.zip) {
            setzip(res.zip)
        }
    }
*/
    const handleChange = async () => {
        try {
            const params = getDeliveryPayload(formRef, currentCart, country, zip, city);
            if (!params.email) {
                return;
            }
            if (!validateEmail(params.email)) {
                return dispatch(setToast({
                    visible: true,
                    type: "danger",
                    title: "Error",
                    text: t('Please set a valid email !')
                }))
            }
            updateInfo(params)
        } catch (err) {
            dispatch(setToast({
                visible: true,
                type: "danger",
                title: "Error",
                text: err.data?.message
            }))
        }
    }

    const updateInfo = async (params, refreshShippings) => {
        const res = await postCart({
            id: currentCart.id,
            params
        }).unwrap()

        if (res.cart) {
            dispatch(setCurrentCart(res.cart));
            if(refreshShippings){
                fetchShippings(res.cart);
            }
        }
    }

    const handleCountryChange = (e) => {
        if (e.target.value) {
            setcountry(e.target.value)
            const data = getStatesByCountryCode(e.target.value.toUpperCase());
            setstates(data)
            const newRegionId = e.target.options[e.target.selectedIndex].getAttribute('region_id');
            if (currentCart.region_id !== newRegionId) {
                updateInfo({
                    region_id: newRegionId
                }, true)
                
            }
        }
    }

    return (
        <>
            <div className="form_infos mb-4">
                <h5 className="mb-4">{currentCart.items[0].variant.is_only_digital ? t('checkout.Information') : t('checkout.Shipping information')}</h5>
                <div className="mb-3">
                    <div className="float-input">
                        <input
                            onBlur={handleChange}
                            required
                            defaultValue={currentCart.email}
                            placeholder={t('checkout.placeholder_email')}
                            type="email"
                            name="email"
                            className="form-control" />
                        <label className="form-label">{t('checkout.Email')} <span className="text-red" >*</span></label>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 mb-3">
                        <div className="float-input">
                            <input
                                onBlur={handleChange}
                                required
                                placeholder={t('checkout.placeholder_firstname')}
                                type="text"
                                name="first_name"
                                maxLength="25"
                                defaultValue={currentCart.shipping_address?.first_name}
                                className="form-control" />
                            <label className="form-label">{t('checkout.First Name')} <span className="text-red" >*</span></label>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-3">
                        <div className="float-input">
                            <input
                                onBlur={handleChange}
                                required
                                placeholder={t('checkout.placeholder_lastname')}
                                type="text"
                                name="last_name"
                                maxLength="25"
                                defaultValue={currentCart.shipping_address?.last_name}
                                className="form-control" />
                            <label className="form-label">{t('checkout.Last Name')} <span className="text-red" >*</span></label>
                        </div>
                    </div>
                </div>
                {!currentCart.items[0].variant.is_only_digital &&
                    <>
                        <div className="row ">
                            <div className="col-12 mb-3">
                                <div className="float-input">
                                    <input
                                        onBlur={handleChange}
                                        required
                                        placeholder={t('checkout.placeholder_address')}
                                        type="text"
                                        name="address_1"
                                        maxLength="35"
                                        defaultValue={currentCart.shipping_address?.address_1}
                                        className="form-control" />
                                    <label className="form-label">{t('checkout.Address')} <span className="text-red" >*</span></label>
                                </div>
                            </div>
                            {!additionalAddress && <small className="link mb-3 d-flex align-items-center" onClick={() => setadditionalAddress(true)}><BsPlusCircle size={15} color="#0162ff" /> <span>{t('checkout.addAdress2')}</span></small>}
                            {additionalAddress && 
                                <div className="col-12 mb-3">
                                    <div className="float-input">
                                        <input
                                            onBlur={handleChange}
                                            maxLength="35"
                                            placeholder={t('checkout.placeholder_address2')}
                                            type="text"
                                            name="address_2"
                                            defaultValue={currentCart.shipping_address?.address_2}
                                            className="form-control" />
                                        <label className="form-label">{t('checkout.Address2')}</label>
                                    </div>
                                </div>
                            }
                            <div className="col-lg-6 mb-3">
                                <div className="float-input">
                                    <div className="select--arrow"></div>
                                    <select
                                        value={country}
                                        onChange={handleCountryChange}
                                        onBlur={handleChange}
                                        required
                                        placeholder={t('checkout.placeholder_country')}
                                        className="form-select"
                                    >
                                        {countries.slice().sort((a, b) => a.iso_2.localeCompare(b.iso_2)).map((country) => (
                                            <option value={country.iso_2} region_id={country.region_id} key={country.id}>{country.display_name}</option>
                                        ))}
                                    </select>
                                    <label className="form-label">{t('checkout.Country')} <span className="text-red" >*</span></label>
                                </div>
                            </div>
                            {states.length > 0 &&
                                <div className="col-lg-6 mb-3">
                                    <div className="float-input">
                                        <div className="select--arrow"></div>
                                        <select
                                            key={currentCart.shipping_address?.province ? currentCart.shipping_address?.province : state}
                                            onBlur={handleChange}
                                            required
                                            name="province"
                                            defaultValue={currentCart.shipping_address?.province ? currentCart.shipping_address?.province : state}
                                            placeholder={t('checkout.placeholder_province')}
                                            className="form-select"
                                        >
                                            <option key="" value="" disabled hidden>
                                              {t('checkout.selectState')}
                                            </option>
                                            {states.map((state) => (
                                                <option value={state.state_code} key={state.state_code}>{state.name}</option>
                                            ))}
                                        </select>
                                        <label className="form-label">{t('checkout.Province')} <span className="text-red" >*</span></label>
                                    </div>
                                </div>}
                            <div className="col-lg-6 mb-3">
                                <div className="float-input">
                                    <input
                                        value={currentCart.shipping_address?.postal_code ? currentCart.shipping_address?.postal_code : zip}
                                        onChange={(e) => setzip(e.target.value)}
                                        onBlur={handleChange}
                                        required
                                        placeholder={t('checkout.placeholder_zipcode')}
                                        type="text"
                                        maxLength="15"
                                        className="form-control" />
                                    <label className="form-label">{t('checkout.Zip code')} <span className="text-red" >*</span></label>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-3">
                                <div className="float-input">
                                    <input
                                        value={currentCart.shipping_address?.city ? currentCart.shipping_address?.city : city}
                                        onChange={(e) => setcity(e.target.value)}
                                        onBlur={handleChange}
                                        required
                                        placeholder={t('checkout.placeholder_city')}
                                        type="text"
                                        maxLength="30"
                                        className="form-control" />
                                    <label className="form-label">{t('checkout.City')} <span className="text-red" >*</span></label>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-2">
                            <div className="float-input float-phone-input">
                                <PhoneInput
                                    defaultCountry={currentCart.shipping_address?.country_code}
                                    preferredCountries={countries.slice().sort((a, b) => a.iso_2.localeCompare(b.iso_2)).map(function (item) { return item.iso_2; })}
                                    onBlur={handleChange}
                                    name="phone"
                                    defaultValue={currentCart.shipping_address?.phone || ""}
                                    onChange={(val) => {
                                        //setphone(val)
                                    }}
                                />
                               <label className="form-label">{t('checkout.placeholder_phone')}</label>
                              </div>
                            </div>
                            <p className="info_phone"><BsInfoCircle size={18} color="#282828" /> <span>{t('checkout.phoneInfo')}</span></p>
                        </div>

                    </>}
            </div>
        </>
    );
}

export default Component;