
import React from "react";
import { useTranslation } from "react-i18next";
import { StripeForm, SvgPaypal, SvgLogoPaypal, SvgLogoCard } from "../../components";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useRef } from "react";
const stripePromise = loadStripe(process.env.REACT_APP_PUB_KEY_STRIPE);

const Page = ({ confirming, setconfirming, paying, setpaying, availableProviders,paymentSession,setpaymentSession }) => {
    const paymentRef = useRef([]);
    const { t } = useTranslation();

    return (
        <>
            <h5 className="mb-4" >{t('checkout.Payment method')}</h5>
            <div className="accordion mb-5" id="payments">
                {availableProviders.slice().sort((a, b) => -1 * a.id.localeCompare(b.id)).map((el, i) => (
                    <div className={`accordion-item ${el.id === paymentSession.id ? 'disabled' : ''}`} key={el.id} >
                        <h2 className="accordion-header" id={`heading_${el.id}`}>
                            <button
                                onClick={() => {
                                    paymentRef.current[i].click();
                                }}
                                className={`accordion-button ${el.id !== paymentSession.id ? 'collapsed' : ''}`}
                                type="button" data-bs-toggle="collapse"
                                data-bs-target={`#collapse_${el.id}`} aria-expanded={el.id === paymentSession.id ? "true" : "false"}
                                aria-controls={`collapse_${el.id}`}>
                                <input
                                    onChange={() => setpaymentSession(el)}
                                    ref={ref => paymentRef.current.push(ref)}
                                    defaultChecked={el.id === paymentSession.id}
                                    className="form-check-input"
                                    type="radio"
                                    name="payment_type"
                                    value={el.id} />
                                <label className="form-check-label text-capitalize">
                                    {el.id == "stripe" ? t('Credit card') : el.id }
                                </label>
                                <div className="payment_logo">
                                    { el.id == "paypal" && <SvgLogoPaypal/>}
                                    { el.id == "stripe" && <SvgLogoCard/>}
                                </div>
                            </button>
                        </h2>
                        <div id={`collapse_${el.id}`} className={`accordion-collapse collapse ${el.id === paymentSession.id ? 'show' : ''}`} aria-labelledby={`heading_${el.id}`} data-bs-parent="#payments">
                            <div className="payment p-4" >
                                {el.id === "paypal" &&
                                    <div className="text-center" >
                                        <SvgPaypal />
                                        <p className="mt-3 mb-0 broken-text" >{t('checkout.Paypal Text')}</p>
                                    </div>
                                }
                                {el.id === "manual" &&
                                        <p className="p-2 mb-0 broken-text" >{t('Please pay money directly to the postman, if you choose cash on delivery method (COD).')}</p>
                                }
                                
                                {el.id === "stripe" &&
                                    <Elements stripe={stripePromise} >
                                        <StripeForm
                                            confirming={confirming} 
                                            setconfirming={setconfirming}
                                            paying={paying} 
                                            setpaying={setpaying}
                                        />
                                    </Elements>
                                }
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}

export default Page;