import apiClient from './apiClient'

export const api = apiClient.injectEndpoints({
    endpoints: (builder) => ({
        createCart: builder.mutation({
            query: (body) => ({
                url: `/store/atc/variant`,
                method: 'POST',
                body
            }),
        }),
        addProductToCart: builder.mutation({
            query: ({id, params}) => ({
                url: `/store/carts/${id}/line-items`,
                method: 'POST',
                body:params
            }),
        }),
        getCart: builder.mutation({
            query: (id) => ({
                url: `/store/carts/${id}`,
                method: 'GET'
            }),
        }),
        postCart: builder.mutation({
            query: ({ id, params }) => ({
                url: `/store/carts/${id}`,
                method: 'POST',
                body: params
            }),
        }),
        deleteDiscount: builder.mutation({
            query: ({ id, discount }) => ({
                url: `/store/carts/${id}/discounts/${discount}`,
                method: 'DELETE',
            }),
        }),
        getShippings: builder.mutation({
            query: (id) => ({
                url: `/store/shipping-options/${id}`,
                method: 'GET'
            }),
        }),
        editShippingMethod: builder.mutation({
            query: ({ id, params }) => ({
                url: `/store/carts/${id}/shipping-methods`,
                method: 'POST',
                body: params
            }),
        }),
        addPaymentSessions: builder.mutation({
            query: (id) => ({
                url: `/store/carts/${id}/payment-sessions`,
                method: 'POST',
                body: {}
            }),
        }),
        completeCart: builder.mutation({
            query: (id) => ({
                url: `/store/carts/${id}/complete`,
                method: 'POST',
                body: {}
            }),
        }),
        setPaymentSession: builder.mutation({
            query: ({ id, params }) => ({
                url: `/store/carts/${id}/payment-session`,
                method: 'POST',
                body: params
            }),
        }),
        editTip: builder.mutation({
            query: ({ id, params }) => ({
                url: `/store/carts/${id}/tip`,
                method: 'POST',
                body: params
            }),
        }),
        getTips: builder.mutation({
            query: () => ({
                url: `/store/tips`,
                method: 'GET',
            }),
        }),
        createUpsell: builder.mutation({
            query: (params) => ({
                url: `/store/carts/upsell`,
                method: 'POST',
                body: params
            }),
        }),
        createPaymentMethod: builder.mutation({
            query: (params) => ({
                url: `/store/stripe/payment-method`,
                method: 'POST',
                body: params
            }),
        }),
        completeUpsell: builder.mutation({
            query: (params) => ({
                url: `/store/carts/upsell/complete`,
                method: 'POST',
                body: params
            }),
        }),
        getCountries: builder.mutation({
            query: () => ({
                url: `/store/countries`,
                method: 'GET',
            }),
        }),
    }),
    overrideExisting: true,
})

export const {
    useCreateCartMutation,
    useAddProductToCartMutation,
    useGetCartMutation,
    usePostCartMutation,
    useDeleteDiscountMutation,
    useGetShippingsMutation,
    useEditShippingMethodMutation,
    useAddPaymentSessionsMutation,
    useCompleteCartMutation,
    useSetPaymentSessionMutation,
    useEditTipMutation,
    useGetTipsMutation,
    useCreateUpsellMutation,
    useCreatePaymentMethodMutation,
    useCompleteUpsellMutation,
    useGetCountriesMutation
} = api
