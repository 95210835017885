
import React, { useCallback, useState } from "react";
import { Loader } from "../../components";
import { useEffect } from "react";
import { useCompleteCartMutation, useCompleteUpsellMutation, useCreateUpsellMutation } from "../../services/cart";
import { useQuery } from "../../hooks/router";
import { useHistory } from "react-router-dom";
import { setCurrentCart } from "../../slices/cart";
import { useDispatch } from "react-redux";

const Page = () => {
    const dispatch = useDispatch();
    const id = useQuery().get('id');
    const parent_cart_id = useQuery().get('parent_cart_id');
    const sold_variant = useQuery().get('sold_variant');
    const history = useHistory();
    const [completCart, { isLoading }] = useCompleteCartMutation();
    const [completUpsell, { isLoading: isLoading2 }] = useCompleteUpsellMutation();
    const [createUpsell, { isLoading:isLoading3 }] = useCreateUpsellMutation();

    const fetchCart = useCallback(async () => {
        if (!isLoading && id) {
            try {
                let res;
                if (parent_cart_id) {
                    res = await completUpsell({
                        cart_id: `cart_${id}`,
                        parent_cart_id
                    }).unwrap();
                } else {
                    res = await completCart(`cart_${id}`).unwrap();
                }
                if (res.data.items) {
                    const i = res.data.items?.findIndex(el => el.variant_id == sold_variant);
                    const upsell_id = res.data.items[i > -1 ? i : 0]?.variant?.upsell_id;
                    if (upsell_id) {
                        const res = await createUpsell({
                            prod_id:upsell_id,
                            cart_id:`cart_${id}`
                        }).unwrap();
            
                        if (res.cart) {
                            dispatch(setCurrentCart(res.cart))
                            history.replace(`/upsell?prod_id=${upsell_id}&cart_id=cart_${id}&created=true`)
                        }
                    } else {
                        history.replace(`/success/${id}`)
                    }
                }
            } catch (error) {
                history.replace(`/success/${id}`)

            }
        }
    }, [id, completUpsell, completCart, history, isLoading])

    useEffect(() => {
        fetchCart()
    }, []);

    return (
        <div className="checkout" >
            <section className="section1" >
                <Loader className={"flex-center"} />
            </section >
        </div >
    );
}

export default Page;

/**
 *  Channel	
370 in stock for 4 variant(s)
790 in stock for 8 variant(s)
 */